import React from "react"
import '../styles/headline_focus.module.css'
import SEO from '../components/seo'
import ProjectCards from '../components/project_components/ProjectCards'
import HeadlineWithFocus from '../components/HeadlineWithFocus.js';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

const TestPage = ({data}) => (
  <div>
  <FontAwesomeIcon icon={['fab', 'github-square']}/>
  <FontAwesomeIcon icon={['fab', 'instagram']}/>
  <FontAwesomeIcon icon={['fa', 'code-branch']}/>
  <ProjectCards />
  </div>
)

/*
<Img
  fluid={coverImage.childImageSharp.fluid}
  className={style.coverImage}
/>
*/

export default TestPage
